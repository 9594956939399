<template>
  <b-card class="p-2 p-lg-4 w-100">
    <b-card-title
      title-tag="h2"
      class="font-weight-bold mb-1"
    >
      Здравствуйте, {{ currentUser.name }}
    </b-card-title>
    <b-card-text
      v-if="isSuccess"
      class="mb-2"
    >
      Ваш e-mail подтверждён
      <a href="/">Перейти на главную</a>
    </b-card-text>
    <b-card-text
      v-else
      class="mb-2"
    >
      К сожалению, ваш e-mail не подтвердился
    </b-card-text>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardText,
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import Auth from '@/api/http/models/auth/Auth'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardText,
  },
  data() {
    return {
      isSuccess: false,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'user/currentUser',
    }),
  },
  async created() {
    const response = await Auth.verify(this.$route.params.verify)

    this.isSuccess = response.status === 'success' || response.status === 'verified'
  },
}
</script>
